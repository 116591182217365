<template>
  <div
    class="card-cursor card pt-2 pb-4 ps-3 pe-3"
    :class="(checked && 'card-checked') || 'card-unchecked'"
    @click="handleSelected"
  >
    <div class="pb-2">
      <label class="form-check-label"
        ><input
          class="form-check-input"
          type="radio"
          :checked="checked"
          :class="$direction === 'rtl' ? 'ms-2' : 'me-2'"
        />{{ $t('document.nonTemplateCard.radioTitle') }}</label
      >
    </div>

    <div class="card-content-wrapper">
      <div class="pb-5">
        <div class="row align-items-center">
          <div class="row p-0 mb-2">
            <p :class="`${!checked ? 'text-disabled' : 'text-typography-primary'} title`">
              {{ $t('document.nonTemplateCard.manualFormat') }}
            </p>
          </div>
          <div class="row p-0">
            <div class="form-check p-0">
              <el-radio-group
                :value="manualFormat === true || manualFormat === 'true' ? 'true' : 'false'"
                :disabled="!checked"
                @input="handleIsManualChange"
              >
                <el-radio class="mx-2" label="true" size="large">
                  <span>
                    {{ $t('document.nonTemplateCard.manualFormatOptions.yes') }}
                  </span>
                </el-radio>
                <el-radio class="mx-2" label="false" size="large">
                  <span>
                    {{ $t('document.nonTemplateCard.manualFormatOptions.no') }}
                  </span></el-radio
                >
              </el-radio-group>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="row align-items-center">
          <div class="row p-0 mb-2">
            <p :class="`${!checked ? 'text-disabled' : 'text-typography-primary'} title`">
              {{ $t('document.nonTemplateCard.localize') }}
            </p>
          </div>
          <div class="row p-0">
            <el-select
              v-model="selectedLocale"
              size="mini"
              class="w-100"
              :class="hasLocaleErrorFlag ? 'select-error' : ''"
              :placeholder="''"
              :disabled="!checked"
              @change="handleLocaleChanged"
            >
              <el-option
                v-for="locale in locales"
                :key="locale.placeholder"
                :value="locale.value"
                :label="locale.placeholder"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    hasLocaleErrorFlag: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['selected', 'locale-changed', 'is-manual-changed'],
  data() {
    return {
      selectedLocale: null,
      manualFormat: false,
      locales: [
        {
          placeholder: this.$t(`automation.templateManagement.createModal.locale.he-IL`),
          value: this.$t(`automation.templateManagement.createModal.locale.he-IL`),
        },
        {
          placeholder: this.$t(`automation.templateManagement.createModal.locale.en-US`),
          value: this.$t(`automation.templateManagement.createModal.locale.en-US`),
        },
      ],
    };
  },
  methods: {
    handleSelected() {
      this.$emit('selected');
    },
    handleLocaleChanged(locale) {
      this.selectedLocale = locale;
      this.$emit('locale-changed', locale);
    },
    handleIsManualChange(e) {
      const isManual = e === 'true';
      this.manualFormat = isManual;
      this.$emit('is-manual-changed', isManual);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
@import '@/modules/document/components/document-global.scss';

::v-deep .select-error .el-input__inner {
  border-color: red;
}

.card-cursor {
  cursor: pointer;
}

.card-checked {
  background-color: #f8fafb;
  border-color: $typography-secondary;
}

.title {
  font-weight: 500;
}

.card-content-wrapper {
  background-color: #f3f3f4;
  padding: 40px;
  width: 100%;
  height: auto;
  aspect-ratio: $a4-ratio-viewer;
}

.card-cursor:hover {
  background-color: #f8fafb;
}

.form-check-label {
  cursor: pointer;
  font-size: $font-size-medium;
  font-weight: 500;
}
</style>
