<template>
  <div>
    <Tabs :active-tab.sync="activeTab" :tabs="tabs" start-space="2em" />
    <div class="p-4">
      <!-- general -->
      <template v-if="activeTab === 0">
        <div v-if="edit || mainForm.documentNumber" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.documentNumber" :disabled="isDisabled('documentNumber')" />
            <p>
              {{ $t('document.exports.schema.fields.documentNumberSpField') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.documentNumberSpField') }}</p>
        </div>

        <div v-if="edit || mainForm.allocationNumber" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.allocationNumber" :disabled="isDisabled('allocationNumber')" />
            <p>
              {{ $t('document.exports.schema.fields.allocationNumber') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.allocationNumber') }}</p>
        </div>

        <div v-if="edit || mainForm.orderReference" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.orderReference" :disabled="isDisabled('orderReference')" />
            <p>
              {{ $t('document.exports.schema.fields.orderReference') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.orderReference') }}</p>
        </div>
        <div v-if="edit || mainForm.issueDate" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.issueDate" :disabled="isDisabled('issueDate')" />
            <p>
              {{ $t('document.exports.schema.fields.issueDate') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.issueDate') }}</p>
        </div>
        <div v-if="edit || mainForm.deliveryDate" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.deliveryDate" :disabled="isDisabled('deliveryDate')" />
            <p>
              {{ $t('document.exports.schema.fields.deliveryDate') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.deliveryDate') }}</p>
        </div>

        <div v-if="edit || mainForm.netAmount" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.netAmount" :disabled="isDisabled('netAmount')" />
            <p>
              {{ $t('document.exports.schema.fields.netAmount') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.netAmount') }}</p>
        </div>

        <div v-if="edit || mainForm.taxableAmount" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.taxableAmount" :disabled="isDisabled('taxableAmount')" />
            <p>
              {{ $t('document.exports.schema.fields.taxableAmount') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.taxableAmount') }}</p>
        </div>

        <div v-if="edit || mainForm.nonTaxableAmount" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.nonTaxableAmount" :disabled="isDisabled('nonTaxableAmount')" />
            <p>
              {{ $t('document.exports.schema.fields.nonTaxableAmount') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.nonTaxableAmount') }}</p>
        </div>

        <div v-if="edit || mainForm.taxRate" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.taxRate" :disabled="isDisabled('taxRate')" />
            <p>
              {{ $t('document.exports.schema.fields.taxRate') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.taxRate') }}</p>
        </div>

        <div v-if="edit || mainForm.taxAmount" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.taxAmount" :disabled="isDisabled('taxAmount')" />
            <p>
              {{ $t('document.exports.schema.fields.taxAmount') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.taxAmount') }}</p>
        </div>

        <div v-if="edit || mainForm.totalAmount" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.totalAmount" :disabled="isDisabled('totalAmount')" />
            <p>
              {{ $t('document.exports.schema.fields.totalAmount') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.totalAmount') }}</p>
        </div>
        <div v-if="edit || mainForm.amountDue" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.amountDue" :disabled="isDisabled('amountDue')" />
            <p>
              {{ $t('document.exports.schema.fields.amountDue') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.amountDue') }}</p>
        </div>
        <div v-if="edit || mainForm.paidAmount" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.paidAmount" :disabled="isDisabled('paidAmount')" />
            <p>
              {{ $t('document.exports.schema.fields.paidAmount') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.paidAmount') }}</p>
        </div>

        <div v-if="edit || mainForm.paymentDate" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.paymentDate" :disabled="isDisabled('paymentDate')" />
            <p>
              {{ $t('document.exports.schema.fields.paymentDate') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.paymentDate') }}</p>
        </div>

        <div v-if="edit || mainForm.paymentMethod" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.paymentMethod" :disabled="isDisabled('paymentMethod')" />
            <p>
              {{ $t('document.exports.schema.fields.paymentMethod') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.paymentMethod') }}</p>
        </div>

        <div v-if="edit || mainForm.openingBalance" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.openingBalance" :disabled="isDisabled('openingBalance')" />
            <p>
              {{ $t('document.exports.schema.fields.openingBalance') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.openingBalance') }}</p>
        </div>

        <div v-if="edit || mainForm.discountRate" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.discountRate" :disabled="isDisabled('discountRate')" />
            <p>
              {{ $t('document.exports.schema.fields.discountRate') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.discountRate') }}</p>
        </div>

        <div v-if="edit || mainForm.discountAmount" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.discountAmount" :disabled="isDisabled('discountAmount')" />
            <p>
              {{ $t('document.exports.schema.fields.discountAmount') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.discountAmount') }}</p>
        </div>

        <div v-if="edit || mainForm.referencesFromDate" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.referencesFromDate" :disabled="isDisabled('referencesFromDate')" />
            <p>
              {{ $t('document.exports.schema.fields.referencesFromDate') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.referencesFromDate') }}</p>
        </div>

        <div v-if="edit || mainForm.referencesToDate" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.referencesToDate" :disabled="isDisabled('referencesToDate')" />
            <p>
              {{ $t('document.exports.schema.fields.referencesToDate') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.referencesToDate') }}</p>
        </div>

        <div v-if="edit || mainForm.paymentDueDate" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.paymentDueDate" :disabled="isDisabled('paymentDueDate')" />
            <p>
              {{ $t('document.exports.schema.fields.paymentDueDate') }}
            </p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.fields.paymentDueDate') }}</p>
        </div>

        <div v-if="edit || Boolean(mainForm.rounding)">
          <template v-if="edit">
            <div class="structure-field">
              <el-checkbox
                :value="Boolean(mainForm.rounding)"
                :disabled="isDisabled('rounding')"
                @change="handleRoundingChange"
              />
              <p>
                {{ $t('document.exports.schema.fields.rounding') }}
              </p>
            </div>
            <el-radio-group v-model="roundingType" :disabled="!mainForm.rounding" class="structure-radio-group">
              <el-radio label="false">
                {{ $t('document.documentRecording.structureParamsModal.params.general.credit') }}
              </el-radio>
              <el-radio label="true">
                {{ $t('document.documentRecording.structureParamsModal.params.general.debit') }}
              </el-radio>
            </el-radio-group>
          </template>
          <div v-else class="structure-field">
            <p class="list-item">
              {{ $t('document.exports.schema.fields.rounding') }} ({{
                mainForm.rounding.debit
                  ? $t('document.documentRecording.structureParamsModal.params.general.debit')
                  : $t('document.documentRecording.structureParamsModal.params.general.credit')
              }})
            </p>
          </div>
        </div>

        <div v-if="edit || mainForm.items" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.items" :disabled="isDisabled('items')" />
            <p>{{ $t('document.documentRecording.structureParamsModal.params.hasItems') }}</p>
          </template>

          <p v-else class="list-item">{{ $t('document.documentRecording.structureParamsModal.params.hasItems') }}</p>
        </div>

        <div v-if="edit || generalChargesState" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="generalChargesState" :disabled="isDisabled('generalCharges')">
              <p>{{ $t('document.documentRecording.structureParamsModal.params.hasGeneralCharges') }}</p>
            </el-checkbox>
          </template>

          <p v-else class="list-item">
            {{ $t('document.documentRecording.structureParamsModal.params.hasGeneralCharges') }}
          </p>
        </div>

        <div v-if="edit || mainForm.references" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="mainForm.references" :disabled="isDisabled('references')" />
            <p>{{ $t('document.documentRecording.structureParamsModal.params.hasReferences') }}</p>
          </template>

          <p v-else class="list-item">
            {{ $t('document.documentRecording.structureParamsModal.params.hasReferences') }}
          </p>
        </div>
      </template>

      <!-- items -->
      <template v-if="activeTab === 1">
        <div v-if="edit || itemsForm.reference" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="itemsForm.reference" :disabled="isDisabled('items.references')" />
            <p>{{ $t('document.exports.schema.itemsFields.reference') }}</p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.itemsFields.reference') }}</p>
        </div>

        <div v-if="edit || itemsForm.name" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="itemsForm.name" />
            <p>{{ $t('document.exports.schema.itemsFields.name') }}</p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.itemsFields.name') }}</p>
        </div>

        <div v-if="edit || itemsForm.sku" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="itemsForm.sku" />
            <p>{{ $t('document.exports.schema.itemsFields.sku') }}</p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.itemsFields.sku') }}</p>
        </div>

        <div v-if="edit || itemsForm.gtin" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="itemsForm.gtin" />
            <p>{{ $t('document.exports.schema.itemsFields.gtin') }}</p>
          </template>

          <p v-else class="list-item">{{ $t('document.exports.schema.itemsFields.gtin') }}</p>
        </div>

        <div v-if="edit || Boolean(itemsForm.price)">
          <template v-if="edit">
            <div class="structure-field">
              <el-checkbox :value="Boolean(itemsForm.price)" @change="handleItemPriceChange" />
              <p>{{ $t('document.exports.schema.itemsFields.price') }}</p>
            </div>
            <el-radio-group v-model="itemPriceTaxType" :disabled="!itemsForm.price" class="structure-radio-group">
              <el-radio label="false">
                {{ $t('document.documentRecording.structureParamsModal.params.excludingTax') }}
              </el-radio>
              <el-radio label="true">
                {{ $t('document.documentRecording.structureParamsModal.params.includingTax') }}
              </el-radio>
            </el-radio-group>
          </template>
          <div v-else class="structure-field">
            <p class="list-item">
              {{ $t('document.exports.schema.itemsFields.price') }} ({{
                itemsForm.price.includingTax
                  ? $t('document.documentRecording.structureParamsModal.params.includingTax')
                  : $t('document.documentRecording.structureParamsModal.params.excludingTax')
              }})
            </p>
          </div>
        </div>

        <div v-if="edit || itemsForm.netPrice" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="itemsForm.netPrice" />
            <p>
              {{
                `${$t('document.exports.schema.itemsFields.netPrice')} (${$t(
                  'document.documentRecording.structureParamsModal.paramsDescriptions.items.netPrice'
                )})`
              }}
            </p>
          </template>

          <p v-else class="list-item">
            {{
              `${$t('document.exports.schema.itemsFields.netPrice')} (${$t(
                'document.documentRecording.structureParamsModal.paramsDescriptions.items.netPrice'
              )})`
            }}
          </p>
        </div>

        <div v-if="edit || itemsForm.quantity" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="itemsForm.quantity" />
            <p>{{ $t('document.exports.schema.itemsFields.quantity') }}</p>
          </template>

          <p v-else class="list-item">
            {{ $t('document.exports.schema.itemsFields.quantity') }}
          </p>
        </div>

        <div v-if="edit || itemsForm.quantityInPackage" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="itemsForm.quantityInPackage" />
            <p>
              {{ $t('document.exports.schema.itemsFields.quantityInPackage') }}
            </p>
          </template>

          <p v-else class="list-item">
            {{ $t('document.exports.schema.itemsFields.quantityInPackage') }}
          </p>
        </div>

        <div v-if="edit || itemsForm.packageQuantity" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="itemsForm.packageQuantity" />
            <p>
              {{ $t('document.exports.schema.itemsFields.packageQuantity') }}
            </p>
          </template>

          <p v-else class="list-item">
            {{ $t('document.exports.schema.itemsFields.packageQuantity') }}
          </p>
        </div>

        <div v-if="edit || itemsForm.discountRate" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="itemsForm.discountRate" />
            <p>
              {{ $t('document.exports.schema.itemsFields.discountRate') }}
            </p>
          </template>

          <p v-else class="list-item">
            {{ $t('document.exports.schema.itemsFields.discountRate') }}
          </p>
        </div>

        <div v-if="edit || itemsForm.discountAmount" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="itemsForm.discountAmount" />
            <p>
              {{
                `${$t('document.exports.schema.itemsFields.discountAmount')} (${$t(
                  'document.documentRecording.structureParamsModal.paramsDescriptions.items.discountAmount'
                )})`
              }}
            </p>
          </template>

          <p v-else class="list-item">
            {{
              `${$t('document.exports.schema.itemsFields.discountAmount')} (${$t(
                'document.documentRecording.structureParamsModal.paramsDescriptions.items.discountAmount'
              )})`
            }}
          </p>
        </div>

        <div v-if="edit || itemsForm.totalDiscount" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="itemsForm.totalDiscount" />
            <p>
              {{ $t('document.exports.schema.itemsFields.totalDiscount') }}
            </p>
          </template>

          <p v-else class="list-item">
            {{ $t('document.exports.schema.itemsFields.totalDiscount') }}
          </p>
        </div>

        <div v-if="edit || itemsForm.totalTax" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="itemsForm.totalTax" />
            <p>{{ $t('document.exports.schema.itemsFields.totalTax') }}</p>
          </template>

          <p v-else class="list-item">
            {{ $t('document.exports.schema.itemsFields.totalTax') }}
          </p>
        </div>

        <div v-if="edit || itemsForm.totalDeposits" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="itemsForm.totalDeposits" />
            <p>
              {{ $t('document.exports.schema.itemsFields.totalDeposits') }}
            </p>
          </template>

          <p v-else class="list-item">
            {{ $t('document.exports.schema.itemsFields.totalDeposits') }}
          </p>
        </div>

        <div v-if="edit || itemsForm.totalPackages" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="itemsForm.totalPackages" />
            <p>
              {{ $t('document.exports.schema.itemsFields.totalPackages') }}
            </p>
          </template>

          <p v-else class="list-item">
            {{ $t('document.exports.schema.itemsFields.totalPackages') }}
          </p>
        </div>

        <div v-if="edit || Boolean(itemsForm.totalAmount)">
          <div v-if="edit" class="d-flex flex-column">
            <div class="structure-field">
              <el-checkbox :value="Boolean(itemsForm.totalAmount)" @change="handleItemTotalChange" />
              <p>
                {{ $t('document.exports.schema.itemsFields.totalAmount') }}
              </p>
            </div>
            <el-radio-group
              v-model="itemTotalAmountTaxType"
              :disabled="!itemsForm.totalAmount"
              class="structure-radio-group"
            >
              <el-radio label="false">
                {{ $t('document.documentRecording.structureParamsModal.params.excludingTax') }}
              </el-radio>
              <el-radio label="true">
                {{ $t('document.documentRecording.structureParamsModal.params.includingTax') }}
              </el-radio>
            </el-radio-group>
            <el-radio-group
              v-model="itemTotalAmountDiscountType"
              :disabled="!itemsForm.totalAmount"
              class="structure-radio-group"
            >
              <el-radio label="false">
                {{ $t('document.documentRecording.structureParamsModal.params.excludingDiscount') }}
              </el-radio>
              <el-radio label="true">
                {{ $t('document.documentRecording.structureParamsModal.params.includingDiscount') }}
              </el-radio>
            </el-radio-group>
          </div>
          <div v-else class="structure-field">
            <p class="list-item">
              {{ $t('document.exports.schema.itemsFields.totalAmount') }}
              ({{
                itemsForm.totalAmount.includingTax
                  ? $t('document.documentRecording.structureParamsModal.params.includingTax')
                  : $t('document.documentRecording.structureParamsModal.params.excludingTax')
              }},
              {{
                itemsForm.totalAmount.includingDiscount
                  ? $t('document.documentRecording.structureParamsModal.params.includingDiscount')
                  : $t('document.documentRecording.structureParamsModal.params.excludingDiscount')
              }})
            </p>
          </div>
        </div>
      </template>

      <!-- references -->
      <template v-if="activeTab === 2">
        <div v-if="edit || referencesForm.documentNumber" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="referencesForm.documentNumber" />
            <p>
              {{ $t('document.documentRecording.structureParamsModal.params.references.documentNumber') }}
            </p>
          </template>

          <p v-else class="list-item">
            {{ $t('document.documentRecording.structureParamsModal.params.references.documentNumber') }}
          </p>
        </div>
        <div v-if="edit || referencesForm.issueDate" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="referencesForm.issueDate" />
            <p>
              {{ $t('document.documentRecording.structureParamsModal.params.references.issueDate') }}
            </p>
          </template>

          <p v-else class="list-item">
            {{ $t('document.documentRecording.structureParamsModal.params.references.issueDate') }}
          </p>
        </div>

        <div v-if="edit || referencesForm.paymentDueDate" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="referencesForm.paymentDueDate" />
            <p>
              {{ $t('document.documentRecording.structureParamsModal.params.references.paymentDueDate') }}
            </p>
          </template>

          <p v-else class="list-item">
            {{ $t('document.documentRecording.structureParamsModal.params.references.paymentDueDate') }}
          </p>
        </div>

        <div v-if="edit || referencesForm.netAmount" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="referencesForm.netAmount" />
            <p>
              {{
                `${$t('document.documentRecording.structureParamsModal.params.references.netAmount')} ${$t(
                  'document.documentRecording.structureParamsModal.paramsDescriptions.references.netAmount'
                )}`
              }}
            </p>
          </template>

          <p v-else class="list-item">
            {{
              `${$t('document.documentRecording.structureParamsModal.params.references.netAmount')} ${$t(
                'document.documentRecording.structureParamsModal.paramsDescriptions.references.netAmount'
              )}`
            }}
          </p>
        </div>

        <div v-if="edit || referencesForm.totalAmount" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="referencesForm.totalAmount" />
            <p>
              {{
                `${$t('document.documentRecording.structureParamsModal.params.references.totalAmount')} (${$t(
                  'document.documentRecording.structureParamsModal.paramsDescriptions.references.totalAmount'
                )})`
              }}
            </p>
          </template>

          <p v-else class="list-item">
            {{
              `${$t('document.documentRecording.structureParamsModal.params.references.totalAmount')} (${$t(
                'document.documentRecording.structureParamsModal.paramsDescriptions.references.totalAmount'
              )})`
            }}
          </p>
        </div>

        <div v-if="edit || referencesForm.debitAmount" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="referencesForm.debitAmount" />
            <p>
              {{ $t('document.documentRecording.structureParamsModal.params.references.debitAmount') }}
            </p>
          </template>

          <p v-else class="list-item">
            {{ $t('document.documentRecording.structureParamsModal.params.references.debitAmount') }}
          </p>
        </div>

        <div v-if="edit || referencesForm.creditAmount" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="referencesForm.creditAmount" />
            <p>
              {{ $t('document.documentRecording.structureParamsModal.params.references.creditAmount') }}
            </p>
          </template>

          <p v-else class="list-item">
            {{ $t('document.documentRecording.structureParamsModal.params.references.creditAmount') }}
          </p>
        </div>

        <div v-if="edit || referencesForm.creditAmount">
          <template v-if="edit">
            <div class="structure-field">
              <el-checkbox :value="Boolean(referencesForm.balance)" @change="handleReferenceBalanceChange" />
              <p>{{ $t('document.documentRecording.structureParamsModal.params.references.balance') }}</p>
            </div>
            <el-radio-group
              v-model="referenceBalanceType"
              :disabled="!referencesForm.balance"
              class="structure-radio-group"
            >
              <el-radio label="false">
                {{ $t('document.documentRecording.structureParamsModal.params.general.credit') }}
              </el-radio>
              <el-radio label="true">
                {{ $t('document.documentRecording.structureParamsModal.params.general.debit') }}
              </el-radio>
            </el-radio-group>
          </template>
          <div v-else class="structure-field">
            <p class="list-item">
              {{ $t('document.documentRecording.structureParamsModal.params.references.balance') }}
              ({{
                referencesForm.balance.debit
                  ? $t('document.documentRecording.structureParamsModal.params.general.debit')
                  : $t('document.documentRecording.structureParamsModal.params.general.credit')
              }})
            </p>
          </div>
        </div>

        <div v-if="edit || referencesForm.items" class="structure-field">
          <template v-if="edit">
            <el-checkbox v-model="referencesForm.items" />
            <p>{{ $t('document.documentRecording.structureParamsModal.params.hasItems') }}</p>
          </template>

          <p v-else class="list-item">
            {{ $t('document.documentRecording.structureParamsModal.params.hasItems') }}
          </p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { clone, reject, isNil, omit } from 'ramda';
import { Tabs } from '@/modules/core';

import { DISABLED_FIELDS } from './constants';

const omitTypename = (key, value) => (key === '__typename' ? undefined : value);
const cloneForm = (data) => JSON.parse(JSON.stringify(data), omitTypename);

const emptyFormTypes = {
  structureParamsForm: {
    netAmount: false,
    taxableAmount: false,
    nonTaxableAmount: false,
    taxRate: false,
    taxAmount: false,
    totalAmount: false,
    amountDue: false,
    openingBalance: false,
    discountRate: false,
    discountAmount: false,
    paymentDueDate: false,
    referencesFromDate: false,
    referencesToDate: false,
    paidAmount: false,
    paymentDate: false,
    paymentMethod: false,
    items: false,
    references: false,
    rounding: null,
    generalCharges: false,
  },

  itemsForm: {
    reference: false,
    name: false,
    sku: false,
    gtin: false,
    price: null,
    netPrice: false,
    quantity: false,
    quantityInPackage: false,
    packageQuantity: false,
    discountRate: false,
    discountAmount: false,
    totalDiscount: false,
    totalTax: false,
    totalDeposits: false,
    totalPackages: false,
    totalAmount: null,
  },

  referencesForm: {
    documentNumber: true,
    issueDate: false,
    orderReference: false,
    deliveryDate: false,
    paymentDueDate: false,
    netAmount: false,
    totalAmount: false,
    debitAmount: false,
    creditAmount: false,
    balance: null,
    items: false,
  },
};

export default {
  components: { Tabs },
  props: {
    form: { type: Object, default: null },
    documentType: { type: String, required: true },
    edit: { type: Boolean, default: true },
  },
  data() {
    const mainForm = {
      ...cloneForm(this.form || emptyFormTypes.structureParamsForm),
      items: Boolean(this.form?.items),
      references: Boolean(this.form?.references),
    };
    const itemsForm = cloneForm(this.form?.items || this.form?.references?.items || emptyFormTypes.itemsForm);
    const referencesForm = {
      ...cloneForm(this.form?.references || emptyFormTypes.referencesForm),
      items: Boolean(this.form?.references?.items),
    };

    return {
      activeTab: 0,
      mainForm,
      itemsForm,
      referencesForm,
    };
  },
  computed: {
    tabs() {
      return [
        {
          text: this.$t('document.documentRecording.structureParamsModal.tabs.general'),
        },
        {
          text: this.$t('document.documentRecording.structureParamsModal.tabs.items'),
          disabled: !this.mainForm.items && !this.referencesForm.items,
        },
        {
          text: this.$t('document.documentRecording.structureParamsModal.tabs.references'),
          disabled: !this.mainForm.references,
        },
      ];
    },
    roundingType: {
      get() {
        return this.mainForm.rounding ? this.mainForm.rounding.debit.toString() : null;
      },
      set(type) {
        this.updateMainForm({
          rounding: {
            debit: type === 'true',
          },
        });
      },
    },
    generalChargesState: {
      get() {
        return this.mainForm.generalCharges?.name || this.mainForm.generalCharges?.amount;
      },
      set(value) {
        this.updateMainForm(
          value
            ? {
                generalCharges: {
                  name: true,
                  amount: true,
                },
              }
            : { generalCharges: undefined }
        );
      },
    },
    referenceBalanceType: {
      get() {
        return this.referencesForm.balance ? this.referencesForm.balance.debit.toString() : null;
      },
      set(type) {
        this.updateReferenceForm({
          balance: {
            debit: type === 'true',
          },
        });
      },
    },
    itemPriceTaxType: {
      get() {
        return this.itemsForm.price ? this.itemsForm.price.includingTax.toString() : null;
      },
      set(type) {
        this.updateItemForm({
          price: {
            includingTax: type === 'true',
          },
        });
      },
    },
    itemTotalAmountTaxType: {
      get() {
        return this.itemsForm.totalAmount ? this.itemsForm.totalAmount.includingTax.toString() : null;
      },
      set(type) {
        this.updateItemForm({
          totalAmount: {
            ...this.itemsForm.totalAmount,
            includingTax: type === 'true',
          },
        });
      },
    },
    itemTotalAmountDiscountType: {
      get() {
        return this.itemsForm.totalAmount ? this.itemsForm.totalAmount.includingDiscount.toString() : null;
      },
      set(type) {
        this.updateItemForm({
          totalAmount: {
            ...this.itemsForm.totalAmount,
            includingDiscount: type === 'true',
          },
        });
      },
    },
  },
  watch: {
    mainForm: {
      handler(newValue) {
        this.emitForm({ mainForm: newValue });
      },
      deep: true,
    },
    itemsForm: {
      handler(newValue) {
        this.emitForm({ itemsForm: newValue });
      },
      deep: true,
    },
    referencesForm: {
      handler(newValue) {
        this.emitForm({ referencesForm: newValue });
      },
      deep: true,
    },
  },
  methods: {
    isDisabled(field) {
      return (DISABLED_FIELDS[field] ?? []).includes(this.documentType);
    },
    handleRoundingChange(checked) {
      if (checked)
        this.updateMainForm({
          rounding: {
            debit: false,
          },
        });
      else this.updateMainForm({ rounding: null });
    },
    handleReferenceBalanceChange(checked) {
      if (checked) this.updateReferenceForm({ balance: { debit: true } });
      else this.updateReferenceForm({ balance: null });
    },
    handleItemPriceChange(checked) {
      if (checked)
        this.updateItemForm({
          price: {
            includingTax: false,
          },
        });
      else this.updateItemForm({ price: null });
    },
    handleItemTotalChange(checked) {
      if (checked)
        this.updateItemForm({
          totalAmount: {
            includingTax: false,
            includingDiscount: false,
          },
        });
      else this.updateItemForm({ totalAmount: null });
    },
    updateMainForm(data) {
      this.mainForm = {
        ...this.mainForm,
        ...data,
      };
    },
    updateItemForm(data) {
      this.itemsForm = {
        ...this.itemsForm,
        ...data,
      };
    },
    updateReferenceForm(data) {
      this.referencesForm = {
        ...this.referencesForm,
        ...data,
      };
    },
    emitForm({ mainForm, referencesForm, itemsForm }) {
      const clearNulls = reject(isNil);
      const newMainForm = mainForm ?? this.mainForm;
      const form = clearNulls(omit(['items', 'references'], newMainForm));
      const references = clearNulls(omit(['items'], referencesForm ?? this.referencesForm));
      const items = clearNulls(itemsForm ?? this.itemsForm);

      if (newMainForm.items) form.items = clone(items);

      if (this.referencesForm.items) references.items = clone(items);

      if (newMainForm.references) form.references = references;

      this.$emit('update:form', form);
    },
  },
};
</script>

<style lang="scss" scoped>
$mb: 1rem;

.structure-field {
  display: flex;

  margin-bottom: $mb;

  > p {
    margin: 0 0.5rem !important;
  }

  .list-item {
    display: list-item;
    list-style-type: disc;
    list-style-position: inside;
    margin: 0 0.25rem !important;
  }
}

.structure-radio-group {
  margin-bottom: $mb;
}
</style>
