<template>
  <div
    class="card-cursor card pt-5 pb-0"
    :class="(checked && 'card-checked') || 'card-unchecked'"
    @click="handleSelected"
  >
    <div class="ps-5 pe-5 pb-5">
      <label class="form-check-label"
        ><input
          class="form-check-input"
          type="radio"
          :checked="checked"
          :class="$direction === 'rtl' ? 'ms-2' : 'me-2'"
        />{{ $t('document.nonTemplateCard.radioTitle') }}</label
      >
    </div>
    <div class="ps-5 pe-5 pb-3">
      <div class="row align-items-center">
        <div class="col-auto">
          <p :class="`${!checked ? 'text-disabled' : 'text-typography-primary'}`">
            {{ $t('document.nonTemplateCard.manualFormat') }}
          </p>
        </div>
        <div class="col-auto">
          <div class="form-check">
            <el-radio-group
              :value="manualFormat === true || manualFormat === 'true' ? 'true' : 'false'"
              :disabled="!checked"
              @input="handleIsManualChange"
            >
              <el-radio class="mx-2" label="true" size="large">
                <span class="mx-1">
                  {{ $t('document.nonTemplateCard.manualFormatOptions.yes') }}
                </span>
              </el-radio>
              <el-radio class="mx-2" label="false" size="large">
                <span class="mx-1">
                  {{ $t('document.nonTemplateCard.manualFormatOptions.no') }}
                </span></el-radio
              >
            </el-radio-group>
          </div>
        </div>
      </div>
    </div>

    <div class="ps-5 pe-5 pb-5">
      <div class="row align-items-center">
        <div class="col-auto">
          <p :class="`${!checked ? 'text-disabled' : 'text-typography-primary'}`">
            {{ $t('document.nonTemplateCard.localize') }}
          </p>
        </div>
        <div class="col-auto">
          <el-select
            v-model="selectedLocale"
            size="mini"
            class="w-100"
            :class="hasLocaleErrorFlag ? 'select-error' : ''"
            :placeholder="''"
            :disabled="!checked"
            @change="handleLocaleChanged"
          >
            <el-option
              v-for="locale in locales"
              :key="locale.placeholder"
              :value="locale.value"
              :label="locale.placeholder"
            >
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    hasLocaleErrorFlag: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  emits: ['selected', 'locale-changed', 'is-manual-changed'],
  data() {
    return {
      selectedLocale: null,
      manualFormat: false,
      locales: [
        {
          placeholder: this.$t(`automation.templateManagement.createModal.locale.he-IL`),
          value: this.$t(`automation.templateManagement.createModal.locale.he-IL`),
        },
        {
          placeholder: this.$t(`automation.templateManagement.createModal.locale.en-US`),
          value: this.$t(`automation.templateManagement.createModal.locale.en-US`),
        },
      ],
    };
  },
  methods: {
    handleSelected() {
      this.$emit('selected');
    },
    handleLocaleChanged(locale) {
      this.selectedLocale = locale;
      this.$emit('locale-changed', locale);
    },
    handleIsManualChange(e) {
      const isManual = e === 'true';
      this.manualFormat = isManual;
      this.$emit('is-manual-changed', isManual);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';
::v-deep .select-error .el-input__inner {
  border-color: red;
}

.card-cursor {
  cursor: pointer;
}

.card-checked {
  background-color: #f8fafb;
  border-color: $typography-secondary;
}

// .card-unchecked {
//   .form-check-input {
//     border-color: $outline;
//     background-color: $white;
//   }
// }

.card-cursor:hover {
  background-color: #f8fafb;
}

.form-check-label {
  cursor: pointer;
}
</style>
