import { render, staticRenderFns } from "./structureParamsTagsNew.vue?vue&type=template&id=5bd18fee&scoped=true"
import script from "./structureParamsTagsNew.vue?vue&type=script&lang=js"
export * from "./structureParamsTagsNew.vue?vue&type=script&lang=js"
import style0 from "./structureParamsTagsNew.vue?vue&type=style&index=0&id=5bd18fee&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bd18fee",
  null
  
)

export default component.exports