<template>
  <el-drawer
    v-if="visible"
    :visible="visible"
    :direction="$direction"
    :modal="false"
    :wrapper-closable="false"
    :show-close="false"
    @close="$emit('update:visible')"
  >
    <template #title>
      <div class="d-flex justify-content-between p-4">
        <span>
          <h2 class="text-typography-primary">
            <span>{{ $t(`document.documentRecording.structureParamsModal.${editable ? 'edit' : 'show'}`) }} </span>
          </h2>
          <h3 class="text-typography-primary">
            {{
              $t('document.documentRecording.structureParamsModal.subtitle', {
                documentType: $t(`document.exports.schema.type.shortName.${currentTemplate.documentType}`),
                supplierName: supplier.name,
              })
            }}
          </h3>

          <Button type="icon" class="p-0" @click="$emit('update:visible')"> <CloseIcon /> </Button
        ></span>
      </div>
    </template>
    <div class="h-100 d-flex flex-column justify-content-between">
      <StructureParamsConfig :form.sync="form" :document-type="currentTemplate.documentType" :edit="editable" />
      <div v-if="!currentTemplate.id" class="d-flex justify-content-end p-4">
        <Button @click="$emit('updateDocument', form)">
          {{ $t('document.documentRecording.structureParamsModal.setForThisDocumentOnly') }}
        </Button>
      </div>
      <div v-else-if="updateMode" class="d-flex justify-content-end p-4">
        <Button @click="$emit('updateTemplate', form)">
          {{ $t('document.documentRecording.structureParamsModal.updateTemplate') }}
        </Button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';

import StructureParamsConfig from './StructureParamsConfig';

export default {
  components: { Button, StructureParamsConfig, CloseIcon },
  props: {
    visible: { type: Boolean, default: false },
    currentTemplate: { type: Object, required: true },
    supplier: { type: Object, required: true },
  },
  data() {
    return {
      form: this.currentTemplate.structureParams,
      updateMode: false,
    };
  },
  computed: {
    editable() {
      return Boolean(!this.currentTemplate.id) || this.updateMode;
    },
  },
  methods: {
    handleAction(action) {
      if (action === 'onUpdateMode') this.updateMode = true;
    },
  },
};
</script>
