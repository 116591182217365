<template>
  <div>
    <div class="box-title">{{ $t('document.structureParamsTags.title') }}</div>
    <div class="mt-5">
      <div v-if="generalTags.length" class="title mb-2">{{ $t('document.structureParamsTags.subTitles.general') }}</div>
      <Tags :values="generalTags" />
    </div>
    <div class="mt-5">
      <div v-if="itemTags.length" class="title mb-2">{{ $t('document.structureParamsTags.subTitles.items') }}</div>
      <Tags :values="itemTags" />
    </div>
    <div class="mt-5">
      <div v-if="referenceTags.length" class="title mb-2">
        {{ $t('document.structureParamsTags.subTitles.references') }}
      </div>
      <Tags :values="referenceTags" />
    </div>
  </div>
</template>

<script>
import Tags from '@/modules/document/components/Tags.vue';

export default {
  components: { Tags },
  props: {
    generalTags: {
      type: Array,
      default: () => [],
    },
    itemTags: {
      type: Array,
      default: () => [],
    },
    referenceTags: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped>
.box-title {
  font-size: 16px;
  font-weight: bold;
}

.title {
  font-size: 14px;
  font-weight: 550;
}
</style>
