import { difference } from 'ramda';

import {
  CONSOLIDATED_DOCS,
  DOCUMENT_TYPES,
  NOTE_DOCS,
  RECONCILIATION_DOCS,
  RECEIPT_DOCS,
  INVOICE_DOCS,
} from '../../types';

const DOCS_WITHOUT_REFERENCES = [
  DOCUMENT_TYPES.BILL_INVOICE,
  DOCUMENT_TYPES.BILL_INVOICE_RECEIPT,
  DOCUMENT_TYPES.PURCHASE_ORDER,
];

const ALL_DOCS = Object.values(DOCUMENT_TYPES);
// Documents Types listed in the field will be disabled e.g. references: ['billInvoice'] -> references will be disabled
export const DISABLED_FIELDS = {
  documentNumber: difference(ALL_DOCS, [DOCUMENT_TYPES.BILL_INVOICE, DOCUMENT_TYPES.DELIVERY_NOTE]),
  allocationNumber: difference(ALL_DOCS, [
    DOCUMENT_TYPES.INVOICE_RECEIPT,
    DOCUMENT_TYPES.INVOICE,
    DOCUMENT_TYPES.CREDIT_NOTE,
  ]),
  issueDate: difference(ALL_DOCS, [DOCUMENT_TYPES.BILL_INVOICE, DOCUMENT_TYPES.DELIVERY_NOTE]),
  orderReference: [...RECONCILIATION_DOCS, DOCUMENT_TYPES.PURCHASE_ORDER],
  deliveryDate: [...RECONCILIATION_DOCS, DOCUMENT_TYPES.PURCHASE_ORDER],
  netAmount: RECONCILIATION_DOCS,
  taxableAmount: RECONCILIATION_DOCS,
  nonTaxableAmount: RECONCILIATION_DOCS,
  taxRate: RECONCILIATION_DOCS,
  taxAmount: RECONCILIATION_DOCS,
  totalAmount: [DOCUMENT_TYPES.RECONCILIATION_STATEMENT],
  openingBalance: difference(ALL_DOCS, [
    DOCUMENT_TYPES.AGED_DEBTORS_REPORT,
    DOCUMENT_TYPES.DELIVERY_NOTE,
    DOCUMENT_TYPES.BILL_INVOICE,
    DOCUMENT_TYPES.INVOICE,
  ]),
  discountRate: RECONCILIATION_DOCS,
  discountAmount: RECONCILIATION_DOCS,
  paymentDueDate: [...NOTE_DOCS, ...RECONCILIATION_DOCS, ...RECEIPT_DOCS],
  referencesFromDate: difference(ALL_DOCS, [DOCUMENT_TYPES.AGED_DEBTORS_REPORT, ...RECEIPT_DOCS]),
  referencesToDate: difference(ALL_DOCS, [DOCUMENT_TYPES.AGED_DEBTORS_REPORT, ...RECEIPT_DOCS]),
  generalCharges: difference(ALL_DOCS, [
    ...NOTE_DOCS,
    ...INVOICE_DOCS,
    DOCUMENT_TYPES.INVOICE_RECEIPT,
    DOCUMENT_TYPES.BILL_INVOICE_RECEIPT,
    DOCUMENT_TYPES.PURCHASE_ORDER,
  ]),
  items: difference(ALL_DOCS, [
    ...CONSOLIDATED_DOCS,
    DOCUMENT_TYPES.INVOICE_RECEIPT,
    DOCUMENT_TYPES.BILL_INVOICE_RECEIPT,
    ...INVOICE_DOCS,
  ]),
  paidAmount: ALL_DOCS,
  paymentDate: difference(ALL_DOCS, [DOCUMENT_TYPES.INVOICE_RECEIPT]),
  paymentMethod: difference(ALL_DOCS, RECEIPT_DOCS),
  amountDue: difference(ALL_DOCS, [DOCUMENT_TYPES.DELIVERY_NOTE, DOCUMENT_TYPES.BILL_INVOICE, DOCUMENT_TYPES.INVOICE]),
  references: [...difference(ALL_DOCS, [...RECEIPT_DOCS, ...INVOICE_DOCS]), ...DOCS_WITHOUT_REFERENCES],
  rounding: RECONCILIATION_DOCS,

  // # Items nested
  'items.references': [DOCUMENT_TYPES.PURCHASE_ORDER],
};
